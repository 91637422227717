import { useMemo } from "react";
import { FixedSizeGrid } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import EventInteractionCard from "../EventInteractionCard";

const GAP = 20;
const Grid = ({
  hasNextPage,
  items,
  loadMoreItems,
  isItemLoaded,
  setScrollRowAndColumn,
  scrollState,
  activeTab,
  handleReviewMedia,
  pageSize,
  approved,
  columnCount = 4,
}) => {
  let itemCount = hasNextPage ? items.length + 1 : items.length;
  const Item = ({ columnIndex, rowIndex, style }) => {
    let content = null;

    const index = columnCount * rowIndex + columnIndex;
    if (isItemLoaded(index)) {
      content = items[index];
    }
    return content ? (
      <div style={style}>
        <EventInteractionCard
          media={content}
          showApproveButton={activeTab === 0}
          handleReviewMedia={handleReviewMedia}
          approved={approved}
        />
      </div>
    ) : (
      <div style={style}></div>
    );
  };
  const itemData = useMemo(
    () => ({
      isItemLoaded,
      items,
    }),
    [isItemLoaded, items]
  );

  return (
    <AutoSizer>
      {({ width, height }) => {
        const GRID_WIDTH = width;
        const GRID_HEIGHT = height;
        const ROW_COUNT = Math.ceil(itemCount / columnCount);
        const COLUMN_WIDTH = width / columnCount - 2;
        const ROW_HEIGHT = (COLUMN_WIDTH - GAP / 2) / 0.5619;
        return (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={pageSize}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeGrid
                height={GRID_HEIGHT}
                width={GRID_WIDTH}
                rowHeight={ROW_HEIGHT}
                columnWidth={COLUMN_WIDTH}
                rowCount={ROW_COUNT}
                columnCount={columnCount}
                itemData={itemData}
                initialScrollTop={ROW_HEIGHT * scrollState.rowIndex}
                onItemsRendered={({
                  visibleRowStartIndex,
                  visibleColumnStartIndex,
                  visibleRowStopIndex,
                  overscanRowStopIndex,
                  overscanRowStartIndex,
                }) => {
                  setScrollRowAndColumn(
                    visibleRowStartIndex,
                    visibleColumnStartIndex
                  );
                  onItemsRendered({
                    overscanStartIndex: overscanRowStartIndex * columnCount,
                    overscanStopIndex: overscanRowStopIndex * columnCount,
                    visibleStartIndex: visibleRowStartIndex * columnCount,
                    visibleStopIndex: visibleRowStopIndex * columnCount,
                  });
                }}
                ref={ref}
              >
                {Item}
              </FixedSizeGrid>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};
export default Grid;
