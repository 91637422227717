import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { URLS } from "../../../constants";
import { uploader } from "../../../utils/uploader";
import { connect } from "redux-zero/react";
import actions from "../../../store/actions";
import Loader from "../../../components/Loader/Loader";
import { CloudUploadOutlined } from "@mui/icons-material";
import "./SocialOperator.css";
import { extractPoster } from "../../../utils/utility";
function BulkUploadModal({ open, onClose, initialState, uploadAfterMath }) {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [coordinates, setCoordinates] = useState([0, 0]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates([position.coords.longitude, position.coords.latitude]);
      },
      (error) => {
        setCoordinates([0, 0]);
      }
    );
  }, []);

  const handleFilesChange = (event) => {
    const files = Array.from(event.target.files);
    const filesWithMetadata = files.map((fileBlob) => ({
      fileBlob,
      fileUrl: URL.createObjectURL(fileBlob),
      videoPosterUrl: undefined,
    }));
    setUploadedFiles(filesWithMetadata);
  };

  async function uploadFile(file) {
    let userLocation = { type: "Point", coordinates: coordinates };
    const payload = {
      isBulkUploaded: true,
      comment: "Bulk Upload",
      userId: "",
      eventId: initialState.event_id,
      userLocation: JSON.stringify(userLocation),
    };
    if (file.videoPosterUrl) {
      payload.videoPosterUrl = file.videoPosterUrl;
    }
    payload["mediaFile"] = file.fileBlob;
    const response = await uploader({
      url: URLS.UPLOAD_MEDIA(),
      body: payload,
    });

    return response;
  }

  const handleUpload = async () => {
    setLoading(true);
    try {
      const uploadPromises = uploadedFiles.map((file) => uploadFile(file));
      const responses = await Promise.all(uploadPromises);

      // Check if all uploads were successful
      const allUploadsSuccessful = responses.every(
        (response) => response.statusCode === 200
      );

      if (allUploadsSuccessful) {
        console.log("All files uploaded successfully:", responses);
        // Clear the states and close the modal
        setUploadedFiles([]);
        onClose();
      } else {
        console.error("Some files were not uploaded successfully:", responses);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      uploadAfterMath();
      setLoading(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Upload Media</DialogTitle>
      <DialogContent sx={{ position: "relative" }}>
        {loading ? (
          <div className="soOverlayLoader">
            <Loader />
          </div>
        ) : (
          ""
        )}
        <input
          type="file"
          multiple
          accept="image/*, video/mp4"
          onChange={handleFilesChange}
          id="upload-button"
          style={{ display: "none" }}
        />
        <label htmlFor="upload-button" style={{ cursor: "pointer" }}>
          <IconButton
            component="span"
            color="primary"
            aria-label="upload"
            size="large"
          >
            <CloudUploadOutlined />
          </IconButton>
          Upload Media Items
        </label>
        <Grid container spacing={2}>
          {uploadedFiles.map((file, index) => {
            const src = file.fileUrl;
            const type = file.fileBlob.type;
            return (
              <Grid item xs={4} key={index}>
                <Card className="soCard">
                  <div style={{ height: "200px" }}>
                    {type.includes("image") ? (
                      <div className="soImageMedia">
                        <img
                          className="soImageMedia"
                          src={src}
                          style={{
                            objectFit: "fill",
                            filter: "blur(10px)",
                          }}
                        />
                        <img
                          className="soImageMedia"
                          src={src}
                          style={{
                            position: "absolute",
                            left: 0,
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="soImageMedia">
                        <video
                          className="soVideoMedia"
                          src={src}
                          autoPlay
                          playsInline
                          loop
                          controls={false}
                          muted
                          style={{
                            objectFit: "fill",
                            filter: "blur(10px)",
                          }}
                          disableRemotePlayback={true}
                        />
                        <video
                          className="soVideoMedia"
                          src={src}
                          autoPlay
                          playsInline
                          loop
                          controls={false}
                          muted
                          style={{
                            zIndex: 11,
                          }}
                          disableRemotePlayback={true}
                          onLoadedMetadata={async (e) => {
                            const videoPosterUrl = await extractPoster(src);
                            file.videoPosterUrl = videoPosterUrl;
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleUpload} color="primary" disabled={loading}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(BulkUploadModal);
